// Webpack Imports
import * as bootstrap from 'bootstrap';


(function() {
    'use strict';
    console.log('opa');

    // Focus input if Searchform is empty
    [].forEach.call(document.querySelectorAll('.search-form'), (el) => {
        el.addEventListener('submit', function(e) {
            var search = el.querySelector('input');
            if (search.value.length < 1) {
                e.preventDefault();
                search.focus();
            }
        });
    });

    // Initialize Popovers: https://getbootstrap.com/docs/5.0/components/popovers
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl, {
            trigger: 'focus',
        });
    });
})();


$(document).ready(function() {
    function changeimg1(){
        var urlimg = $(".sectionGrupa .sectionGroup__item.aos-init.aos-animate:last").attr('data-img-big');
            if(urlimg === undefined){ 
                $(".sectionGrupa .sectionGroup__itemtitle .imgholder__first").fadeIn('fast');
                $(".sectionGrupa .sectionGroup__itemtitle .imgholder").fadeOut('fast');
            }
            else{
                $(".sectionGrupa .sectionGroup__itemtitle .imgholder").fadeOut('fast');
                setTimeout(function(){ 
                    $(".sectionGrupa .sectionGroup__itemtitle .imgholder").css("background", "url('" + urlimg + "') left top no-repeat");
                    $(".sectionGrupa .sectionGroup__itemtitle .imgholder").css("background-size", "contain");
                    $(".sectionGrupa .sectionGroup__itemtitle .imgholder").fadeIn('fast');
                    //$(".sectionGrupa .sectionGroup__itemtitle .imgholder__first").hide();
                }, 230); 
                setTimeout(function(){ 
                    $(".sectionGrupa .sectionGroup__itemtitle .imgholder__first").hide();
                }, 430);  
            };
            //console.log(urlimg);
        }
    document.addEventListener('aos:in:changeImg1', ({ detail }) => {
        changeimg1();
    });

    function changeimg2(){
       //var urlimg = $(".sectionLata .sectionGroup__item.aos-init.aos-animate:last").attr('data-img-big');
        //console.log(urlimg);
     //   $(".sectionLata .sectionGroup__itemtitle picture").hide();
        // $(".sectionLata .sectionGroup__itemtitle picture source").attr('srcset', urlimg);
          //  $(".sectionLata .sectionGroup__itemtitle picture img").attr('src', urlimg);
        //    $(".sectionLata .sectionGroup__itemtitle picture").show();
       /* setTimeout(function(){ 
            $(".sectionLata .sectionGroup__itemtitle picture source").attr('srcset', urlimg);
            $(".sectionLata .sectionGroup__itemtitle picture img").attr('src', urlimg);
            $(".sectionLata .sectionGroup__itemtitle picture").show();
        }, 10);*/
        var urlimg = $(".sectionLata .sectionGroup__item.aos-init.aos-animate:last").attr('data-img-big');
        $(".sectionLata .sectionGroup__itemtitle .imgholder").css("background", "url('" + urlimg + "') left top no-repeat");
                    $(".sectionLata .sectionGroup__itemtitle .imgholder").css("background-size", "contain");
                    
        /*
        if(urlimg === undefined){ 
                $(".sectionLata .sectionGroup__itemtitle .imgholder__first").fadeIn('fast');
                $(".sectionLata .sectionGroup__itemtitle .imgholder").fadeOut('fast');
            }
            else{
                //$(".sectionLata .sectionGroup__itemtitle .imgholder").fadeOut('fast');
                setTimeout(function(){ 
                    $(".sectionLata .sectionGroup__itemtitle .imgholder").css("background", "url('" + urlimg + "') left top no-repeat");
                    $(".sectionLata .sectionGroup__itemtitle .imgholder").css("background-size", "contain");
                    $(".sectionLata .sectionGroup__itemtitle .imgholder").fadeIn('fast');
                    $(".sectionGrupa .sectionGroup__itemtitle .imgholder__first").hide();
                }, 230); 
                setTimeout(function(){ 
                    $(".sectionLata .sectionGroup__itemtitle .imgholder__first").hide();
                }, 430);  
            };
        */}
    document.addEventListener('aos:in:changeImg2', ({ detail }) => {
        changeimg2();
    });



    $(".js-modal-open").click(function() {
        $('.sideContact').addClass("open");
        //$('body').addClass("modal-opened");
        $('.sideContactBg').fadeIn();
    });
    $(".js-modal-close").click(function() {
        $('.sideContact').removeClass("open");
        //$('body').removeClass("modal-opened");
        $('.sideContactBg').fadeOut();
    });
    $(".sideContactBg").click(function() {
        $('.sideContact').removeClass("open");
        //$('body').removeClass("modal-opened");
        $('.sideContactBg').fadeOut();
    });

    $(window).scroll(function() {    
        var scroll = $(window).scrollTop();

        if (scroll >= 500) {
            $(".contactIcon").addClass("show");
        } else {
            $(".contactIcon").removeClass("show");
        }
    });

    
       
    setTimeout(function(){ 
        var stickyEl = new Sticksy('.js-sticky-title', {
            topSpacing: 60,
        })
        stickyEl.onStateChanged = function (state) {
            if (state === 'fixed') stickyEl.nodeRef.classList.add('widget--sticky')
            else stickyEl.nodeRef.classList.remove('widget--sticky')
        }
            var stickyEl2 = new Sticksy('.js-sticky-title2', {
            topSpacing: 60,
        })
        stickyEl2.onStateChanged = function (state) {
            if (state === 'fixed') stickyEl2.nodeRef.classList.add('widget--sticky')
            else stickyEl2.nodeRef.classList.remove('widget--sticky')
        }
    }, 500);

});